export const createMapFromObject = <T>(obj: Record<string, T>): Map<string, T> => {
  return new Map(Object.entries(obj));
};

export const appendObjectFields = (object: Record<string, string>, appendValue: string): Record<string, string> => {
  const map = createMapFromObject(object);
  map.forEach((value, key) => {
    map.set(key, `${appendValue} ${value}`);
  });

  return Object.fromEntries(map.entries());
};

export const formatNumber = (money: string | number | null) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(money));
};

export const formatDate = (timeData: Date | string | null): string => {
  const dayjs = useDayjs();
  return timeData ? dayjs(timeData).format('MM/DD/YYYY hh:mm A') : '';
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeAll = (value: string) => {
  return value
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

export function toMoney(value: number): string {
  if (value === 0) {
    return 'Free!';
  }

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(value));
}
